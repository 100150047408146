@import url(~normalize.css);
@import url(~@blueprintjs/core/lib/css/blueprint.css);
@import url(~@blueprintjs/icons/lib/css/blueprint-icons.css);
@import url(~@blueprintjs/datetime/lib/css/blueprint-datetime.css);
.btn-back-left {
  position: absolute;
  top: 20px;
  left: 20px; }
  .btn-back-left i {
    font-size: 30px; }

.btn-custom-1 {
  background-color: #f9ac19;
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: 1.5px solid #f9ac19;
  transition: 0.3s;
  padding-left: 30px;
  padding-right: 30px; }
  .btn-custom-1:hover {
    background-color: transparent;
    color: #f9ac19; }

.btn-custom-green {
  background-color: #1dae7c;
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: 1.5px solid #1dae7c;
  transition: 0.3s;
  padding-left: 30px;
  padding-right: 30px; }
  .btn-custom-green:hover {
    background-color: transparent;
    color: #1dae7c; }

.btn-custom-blue {
  background-color: dodgerblue;
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: 1.5px solid dodgerblue;
  transition: 0.3s;
  padding-left: 30px;
  padding-right: 30px; }
  .btn-custom-blue:hover {
    background-color: transparent;
    color: dodgerblue; }

.btn-custom-edit {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #ebebeb;
  padding: 10px;
  transition: 0.3s;
  padding-left: 30px;
  padding-right: 30px; }
  .btn-custom-edit:hover {
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    outline: none !important;
    box-shadow: none !important;
    background-color: #ebebeb; }

.btn-custom-2 {
  background-color: #A5A4BF;
  color: white;
  border-radius: 3px;
  padding: 15px;
  border: 1.5px solid #A5A4BF;
  transition: 0.3s; }
  .btn-custom-2:hover {
    background-color: transparent;
    color: #A5A4BF; }

.btn-toggle-inactive {
  background-color: #ebe7e7;
  color: white; }
  .btn-toggle-inactive:hover {
    background-color: #ebe7e7;
    color: white; }

.btn-toggle-active {
  background-color: #f9ac19;
  color: white; }
  .btn-toggle-active:hover {
    background-color: #f9ac19;
    color: white; }

.btn-transparent {
  padding: 10px; }
  .btn-transparent i {
    color: #707070; }

.btn-selected {
  background-color: #f9ac19;
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 50%; }
  .btn-selected i {
    color: white;
    font-size: 15px; }

.btn-selectable {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #ebebeb;
  padding: 15px !important; }
  .btn-selectable:hover {
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    outline: none !important;
    box-shadow: none !important;
    background-color: #ebebeb;
    padding: 15px !important; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap; }

.flex-column-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }

.flex-column-left-2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; }

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap; }

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.flex-between-2 {
  display: flex;
  justify-content: space-between; }

.flex-between-3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.nav-bar {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1.5px solid #ebe7e7;
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5; }
  .nav-bar .notifications {
    position: relative; }
    .nav-bar .notifications i {
      color: #707070 !important; }
    .nav-bar .notifications .counter {
      position: absolute;
      left: 100%;
      bottom: 100%;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: #f9ac19;
      transform: translate(-200%, 100%) !important; }
      .nav-bar .notifications .counter span {
        color: white;
        font-weight: bold;
        margin: 0;
        padding: 0;
        font-size: 12px; }
  .nav-bar .user-module {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1.5px solid #ebe7e7;
    padding-left: 15px; }

.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #43425D;
  color: white;
  z-index: 4; }
  .side-nav .side-nav-header {
    background-color: #2e2d3f;
    padding: 20px; }
    .side-nav .side-nav-header .side-nav-logo {
      width: 100px; }
  .side-nav .side-nav-body {
    list-style: none;
    padding: 0;
    margin-top: 40px; }
    .side-nav .side-nav-body .side-nav-el a {
      transition: 0.3s;
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .side-nav .side-nav-body .side-nav-el a span:nth-child(1) i {
        font-size: 25px;
        color: #A5A4BF; }
      .side-nav .side-nav-body .side-nav-el a span:nth-child(2) {
        font-size: 16px;
        margin-left: 10px; }
        .side-nav .side-nav-body .side-nav-el a span:nth-child(2).active {
          color: #f9ac19; }
      .side-nav .side-nav-body .side-nav-el a:hover {
        background-color: #2e2d3f; }

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-out {
  animation-name: fade-out;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center; }
  .login .logo {
    width: 120px; }
  .login .login-form {
    border: 1px solid #707070;
    color: #ebebeb;
    padding: 50px;
    border-radius: 5px;
    min-height: 450px; }
    .login .login-form .form-group .input-group-text {
      border: 1px solid #e4e4e4;
      background-color: #ebebeb; }
      .login .login-form .form-group .input-group-text .checked {
        color: #39DE3E; }
    .login .login-form .form-group .form-control {
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: white;
      padding: 22px !important;
      text-align: center; }

.circle-loader {
  position: fixed;
  bottom: 20px;
  left: 20px; }
  .circle-loader .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px; }
  .circle-loader .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #707070;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #707070 transparent transparent transparent; }
  .circle-loader .lds-ring div:nth-child(1) {
    animation-delay: -0.45s; }
  .circle-loader .lds-ring div:nth-child(2) {
    animation-delay: -0.3s; }
  .circle-loader .lds-ring div:nth-child(3) {
    animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.dashboard {
  padding-left: 250px;
  min-height: 100vh !important;
  background-color: #ebebeb;
  position: relative; }
  .dashboard .dashboard-items {
    padding: 40px; }
  .dashboard .dash-item {
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    min-width: 200px; }
    .dashboard .dash-item .dash-item-icon i {
      font-size: 60px; }
    .dashboard .dash-item .dash-item-text p {
      margin: 0;
      padding: 0;
      font-size: 20px; }

.items-app h2 {
  font-weight: normal; }

.items-app button i {
  color: #707070; }

.items-app .row .col-8 {
  margin: 0;
  padding: 0; }

.items-app .row .col-sm-6 {
  padding: 0; }

.items-app .row .col-4 {
  margin: 0;
  padding: 0; }

.leads, .companies, .items-web, .reservations, .items-app {
  width: 100%;
  padding: 20px; }
  .leads .document-wrapper .counter, .companies .document-wrapper .counter, .items-web .document-wrapper .counter, .reservations .document-wrapper .counter, .items-app .document-wrapper .counter {
    font-size: 20px; }
  .leads .document-wrapper .document-inner, .companies .document-wrapper .document-inner, .items-web .document-wrapper .document-inner, .reservations .document-wrapper .document-inner, .items-app .document-wrapper .document-inner {
    min-width: 120px;
    height: 120px;
    position: relative;
    padding: 15px;
    border: 1.5px grey dashed;
    border-radius: 5px;
    cursor: pointer; }
    .leads .document-wrapper .document-inner .done, .companies .document-wrapper .document-inner .done, .items-web .document-wrapper .document-inner .done, .reservations .document-wrapper .document-inner .done, .items-app .document-wrapper .document-inner .done {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      color: yellowgreen; }
    .leads .document-wrapper .document-inner .custom-file, .companies .document-wrapper .document-inner .custom-file, .items-web .document-wrapper .document-inner .custom-file, .reservations .document-wrapper .document-inner .custom-file, .items-app .document-wrapper .document-inner .custom-file {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      cursor: pointer; }
      .leads .document-wrapper .document-inner .custom-file .custom-file-input, .companies .document-wrapper .document-inner .custom-file .custom-file-input, .items-web .document-wrapper .document-inner .custom-file .custom-file-input, .reservations .document-wrapper .document-inner .custom-file .custom-file-input, .items-app .document-wrapper .document-inner .custom-file .custom-file-input {
        height: 100%;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
      .leads .document-wrapper .document-inner .custom-file .custom-file-label, .companies .document-wrapper .document-inner .custom-file .custom-file-label, .items-web .document-wrapper .document-inner .custom-file .custom-file-label, .reservations .document-wrapper .document-inner .custom-file .custom-file-label, .items-app .document-wrapper .document-inner .custom-file .custom-file-label {
        height: 100% !important;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
        .leads .document-wrapper .document-inner .custom-file .custom-file-label:after, .companies .document-wrapper .document-inner .custom-file .custom-file-label:after, .items-web .document-wrapper .document-inner .custom-file .custom-file-label:after, .reservations .document-wrapper .document-inner .custom-file .custom-file-label:after, .items-app .document-wrapper .document-inner .custom-file .custom-file-label:after {
          content: "";
          background-color: transparent;
          border: none !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
  .leads .table, .companies .table, .items-web .table, .reservations .table, .items-app .table {
    border: none !important;
    border-top: 2px solid transparent !important; }
    .leads .table thead, .companies .table thead, .items-web .table thead, .reservations .table thead, .items-app .table thead {
      border: none !important; }
      .leads .table thead tr, .companies .table thead tr, .items-web .table thead tr, .reservations .table thead tr, .items-app .table thead tr {
        border: none !important; }
        .leads .table thead tr th, .companies .table thead tr th, .items-web .table thead tr th, .reservations .table thead tr th, .items-app .table thead tr th {
          border-bottom: 2px solid transparent !important; }
          .leads .table thead tr th p, .companies .table thead tr th p, .items-web .table thead tr th p, .reservations .table thead tr th p, .items-app .table thead tr th p {
            font-weight: bold;
            margin: 0 !important;
            padding: 0 !important; }
    .leads .table tbody tr, .companies .table tbody tr, .items-web .table tbody tr, .reservations .table tbody tr, .items-app .table tbody tr {
      border: none !important; }
      .leads .table tbody tr td, .companies .table tbody tr td, .items-web .table tbody tr td, .reservations .table tbody tr td, .items-app .table tbody tr td {
        border: none !important;
        border-top: 1px solid transparent !important;
        padding: 8px; }
        .leads .table tbody tr td p, .companies .table tbody tr td p, .items-web .table tbody tr td p, .reservations .table tbody tr td p, .items-app .table tbody tr td p {
          background-color: white !important;
          padding: 10px;
          margin: 0; }
  .leads .a-buttons button i, .companies .a-buttons button i, .items-web .a-buttons button i, .reservations .a-buttons button i, .items-app .a-buttons button i {
    color: #707070; }
  .leads .company-form, .companies .company-form, .items-web .company-form, .reservations .company-form, .items-app .company-form {
    position: relative; }
    .leads .company-form .photo-element, .companies .company-form .photo-element, .items-web .company-form .photo-element, .reservations .company-form .photo-element, .items-app .company-form .photo-element {
      width: 150px;
      height: 150px;
      border-radius: 3px;
      position: relative;
      border: 1.5px solid #ebe7e7; }
      .leads .company-form .photo-element .btn-clear-img, .companies .company-form .photo-element .btn-clear-img, .items-web .company-form .photo-element .btn-clear-img, .reservations .company-form .photo-element .btn-clear-img, .items-app .company-form .photo-element .btn-clear-img {
        position: absolute;
        bottom: 100%;
        left: 100%;
        background-color: #f9ac19;
        color: white;
        border: none !important;
        outline: none !important;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, 50%); }
      .leads .company-form .photo-element .progress, .companies .company-form .photo-element .progress, .items-web .company-form .photo-element .progress, .reservations .company-form .photo-element .progress, .items-app .company-form .photo-element .progress {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
        width: 70%; }
        .leads .company-form .photo-element .progress .progress-bar, .companies .company-form .photo-element .progress .progress-bar, .items-web .company-form .photo-element .progress .progress-bar, .reservations .company-form .photo-element .progress .progress-bar, .items-app .company-form .photo-element .progress .progress-bar {
          background-color: #f9ac19; }
    .leads .company-form .custom-file, .companies .company-form .custom-file, .items-web .company-form .custom-file, .reservations .company-form .custom-file, .items-app .company-form .custom-file {
      position: absolute;
      top: 0;
      left: 0;
      height: 100px !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      cursor: pointer; }
      .leads .company-form .custom-file .custom-file-input, .companies .company-form .custom-file .custom-file-input, .items-web .company-form .custom-file .custom-file-input, .reservations .company-form .custom-file .custom-file-input, .items-app .company-form .custom-file .custom-file-input {
        height: 100%;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
      .leads .company-form .custom-file .custom-file-label, .companies .company-form .custom-file .custom-file-label, .items-web .company-form .custom-file .custom-file-label, .reservations .company-form .custom-file .custom-file-label, .items-app .company-form .custom-file .custom-file-label {
        height: 100% !important;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
        .leads .company-form .custom-file .custom-file-label:after, .companies .company-form .custom-file .custom-file-label:after, .items-web .company-form .custom-file .custom-file-label:after, .reservations .company-form .custom-file .custom-file-label:after, .items-app .company-form .custom-file .custom-file-label:after {
          content: "";
          background-color: transparent;
          border: none !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
    .leads .company-form .photo-element-add, .companies .company-form .photo-element-add, .items-web .company-form .photo-element-add, .reservations .company-form .photo-element-add, .items-app .company-form .photo-element-add {
      position: relative;
      height: 100px;
      width: 100px;
      border: 1.5px dashed #707070;
      border-radius: 50%;
      cursor: pointer; }
      .leads .company-form .photo-element-add i, .companies .company-form .photo-element-add i, .items-web .company-form .photo-element-add i, .reservations .company-form .photo-element-add i, .items-app .company-form .photo-element-add i {
        font-size: 35px;
        color: #707070; }
    .leads .company-form .form-group .input-group-text, .companies .company-form .form-group .input-group-text, .items-web .company-form .form-group .input-group-text, .reservations .company-form .form-group .input-group-text, .items-app .company-form .form-group .input-group-text {
      border: 1px solid #e4e4e4;
      background-color: #ebebeb; }
      .leads .company-form .form-group .input-group-text .checked, .companies .company-form .form-group .input-group-text .checked, .items-web .company-form .form-group .input-group-text .checked, .reservations .company-form .form-group .input-group-text .checked, .items-app .company-form .form-group .input-group-text .checked {
        color: #39DE3E; }
    .leads .company-form .form-group .form-control, .companies .company-form .form-group .form-control, .items-web .company-form .form-group .form-control, .reservations .company-form .form-group .form-control, .items-app .company-form .form-group .form-control {
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: white;
      padding: 22px !important; }
  .leads .company-item, .companies .company-item, .items-web .company-item, .reservations .company-item, .items-app .company-item {
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    width: 100% !important;
    position: relative; }
    .leads .company-item .company-body, .companies .company-item .company-body, .items-web .company-item .company-body, .reservations .company-item .company-body, .items-app .company-item .company-body {
      padding: 15px; }
      .leads .company-item .company-body .row, .companies .company-item .company-body .row, .items-web .company-item .company-body .row, .reservations .company-item .company-body .row, .items-app .company-item .company-body .row {
        padding: 0;
        margin: 0; }
      .leads .company-item .company-body .profile_img, .companies .company-item .company-body .profile_img, .items-web .company-item .company-body .profile_img, .reservations .company-item .company-body .profile_img, .items-app .company-item .company-body .profile_img {
        width: 70px;
        border-radius: 50%; }
    .leads .company-item .company-footer, .companies .company-item .company-footer, .items-web .company-item .company-footer, .reservations .company-item .company-footer, .items-app .company-item .company-footer {
      padding: 15px;
      border-top: 1px solid #ebe7e7; }
    .leads .company-item .action-buttons, .companies .company-item .action-buttons, .items-web .company-item .action-buttons, .reservations .company-item .action-buttons, .items-app .company-item .action-buttons {
      position: absolute;
      top: 5px;
      right: 5px; }
      .leads .company-item .action-buttons .btn, .companies .company-item .action-buttons .btn, .items-web .company-item .action-buttons .btn, .reservations .company-item .action-buttons .btn, .items-app .company-item .action-buttons .btn {
        padding: 5px; }
        .leads .company-item .action-buttons .btn i, .companies .company-item .action-buttons .btn i, .items-web .company-item .action-buttons .btn i, .reservations .company-item .action-buttons .btn i, .items-app .company-item .action-buttons .btn i {
          font-size: 20px;
          color: #707070; }
  .leads .row, .companies .row, .items-web .row, .reservations .row, .items-app .row {
    width: 100%; }
  .leads .form-group .input-group .input-group-text, .companies .form-group .input-group .input-group-text, .items-web .form-group .input-group .input-group-text, .reservations .form-group .input-group .input-group-text, .items-app .form-group .input-group .input-group-text {
    background-color: white;
    border: none;
    color: #707070;
    padding: 5px !important; }
  .leads .form-group .input-group .form-control, .companies .form-group .input-group .form-control, .items-web .form-group .input-group .form-control, .reservations .form-group .input-group .form-control, .items-app .form-group .input-group .form-control {
    background-color: white;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    height: auto !important;
    padding: 5px !important; }
    .leads .form-group .input-group .form-control:active, .companies .form-group .input-group .form-control:active, .items-web .form-group .input-group .form-control:active, .reservations .form-group .input-group .form-control:active, .items-app .form-group .input-group .form-control:active {
      outline: none !important;
      box-shadow: none !important; }
  .leads .lead-item, .companies .lead-item, .items-web .lead-item, .reservations .lead-item, .items-app .lead-item {
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    width: 100% !important; }
    .leads .lead-item .lead-body, .companies .lead-item .lead-body, .items-web .lead-item .lead-body, .reservations .lead-item .lead-body, .items-app .lead-item .lead-body {
      padding: 10px; }
    .leads .lead-item .lead-footer, .companies .lead-item .lead-footer, .items-web .lead-item .lead-footer, .reservations .lead-item .lead-footer, .items-app .lead-item .lead-footer {
      padding: 10px;
      border-top: 1px solid #ebe7e7; }
    .leads .lead-item .lead-item-origin p, .companies .lead-item .lead-item-origin p, .items-web .lead-item .lead-item-origin p, .reservations .lead-item .lead-item-origin p, .items-app .lead-item .lead-item-origin p {
      font-size: 12px;
      margin: 0;
      padding: 0; }

.modal-proposal-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  overflow: auto;
  z-index: 6;
  animation-name: show-modal;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes show-modal {
  0% {
    top: 100%; }
  100% {
    top: 0; } }
  .modal-proposal-wrapper .btn-close-modal {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #707070;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 8;
    animation-name: show-in-late;
    animation-iteration-count: 1;
    animation-duration: 1s; }

@keyframes show-in-late {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .modal-proposal-wrapper .btn-close-modal i {
      color: white;
      font-size: 30px; }
    .modal-proposal-wrapper .btn-close-modal:active {
      border: none !important;
      outline: none !important; }
    .modal-proposal-wrapper .btn-close-modal:hover {
      border: none !important;
      outline: none !important; }
  .modal-proposal-wrapper .modal-proposal {
    width: 60%;
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    border-radius: 10px;
    position: relative;
    z-index: 6;
    min-height: 450px !important; }
    .modal-proposal-wrapper .modal-proposal .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-proposal-wrapper .modal-proposal .field-element h5 {
        padding: 0;
        margin: 0; }
      .modal-proposal-wrapper .modal-proposal .field-element .header {
        color: #707070;
        font-size: 16px !important;
        padding: 0;
        margin: 0; }
      .modal-proposal-wrapper .modal-proposal .field-element .header-2 {
        font-weight: normal;
        margin-bottom: 0 !important;
        padding: 0 !important;
        font-size: 16px !important; }
      .modal-proposal-wrapper .modal-proposal .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-proposal-wrapper .modal-proposal .field-element a.a-value {
        display: block; }
    .modal-proposal-wrapper .modal-proposal .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-proposal-wrapper .modal-proposal .action-text h1 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-proposal-wrapper .modal-proposal .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-proposal-wrapper .modal-proposal .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-proposal-wrapper .modal-proposal .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-proposal-wrapper .modal-proposal .field-element a.a-value {
        display: block; }
  .modal-proposal-wrapper .mudatodo-img img {
    width: 100px; }

.modal-home-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  overflow: auto;
  z-index: 6;
  animation-name: show-modal;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes show-modal {
  0% {
    top: 100%; }
  100% {
    top: 0; } }
  .modal-home-wrapper .btn-close-modal {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #707070;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 8;
    animation-name: show-in-late;
    animation-iteration-count: 1;
    animation-duration: 1s; }

@keyframes show-in-late {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .modal-home-wrapper .btn-close-modal i {
      color: white;
      font-size: 30px; }
    .modal-home-wrapper .btn-close-modal:active {
      border: none !important;
      outline: none !important; }
    .modal-home-wrapper .btn-close-modal:hover {
      border: none !important;
      outline: none !important; }
  .modal-home-wrapper .modal-home {
    width: 60%;
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    border-radius: 10px;
    position: relative;
    z-index: 6; }
    .modal-home-wrapper .modal-home .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-home-wrapper .modal-home .action-text h1 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-home-wrapper .modal-home .logo-mudapartner {
      width: 120px; }
    .modal-home-wrapper .modal-home .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-home-wrapper .modal-home .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home .field-element a.a-value {
        display: block; }
  .modal-home-wrapper .mudatodo-img img {
    width: 100px; }
  .modal-home-wrapper .modal-home-company-2 {
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    padding-bottom: 40px;
    min-width: 400px;
    border-radius: 10px;
    position: relative;
    z-index: 6; }
    .modal-home-wrapper .modal-home-company-2 .form-group .input-group-text {
      border: 1px solid #e4e4e4;
      background-color: #ebebeb; }
      .modal-home-wrapper .modal-home-company-2 .form-group .input-group-text .checked {
        color: #39DE3E; }
    .modal-home-wrapper .modal-home-company-2 .form-group .form-control {
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: #ebebeb;
      padding: 22px !important; }
    .modal-home-wrapper .modal-home-company-2 .company-logo-absolute {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%); }
      .modal-home-wrapper .modal-home-company-2 .company-logo-absolute img {
        width: 80px; }
    .modal-home-wrapper .modal-home-company-2 .modal-home-text {
      font-size: 20px;
      margin: 0; }
    .modal-home-wrapper .modal-home-company-2 .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-home-wrapper .modal-home-company-2 .action-text h4 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-home-wrapper .modal-home-company-2 .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-home-wrapper .modal-home-company-2 .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company-2 .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company-2 .field-element a.a-value {
        display: block; }
  .modal-home-wrapper .modal-home-company {
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    padding-bottom: 100px;
    min-width: 400px;
    border-radius: 10px;
    position: relative;
    z-index: 6; }
    .modal-home-wrapper .modal-home-company .form-group .input-group-text {
      border: 1px solid #e4e4e4;
      background-color: #ebebeb; }
      .modal-home-wrapper .modal-home-company .form-group .input-group-text .checked {
        color: #39DE3E; }
    .modal-home-wrapper .modal-home-company .form-group .form-control {
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: #ebebeb;
      padding: 22px !important; }
    .modal-home-wrapper .modal-home-company .company-logo-absolute {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%); }
      .modal-home-wrapper .modal-home-company .company-logo-absolute img {
        width: 80px; }
    .modal-home-wrapper .modal-home-company .modal-home-text {
      font-size: 20px;
      margin: 0; }
    .modal-home-wrapper .modal-home-company .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-home-wrapper .modal-home-company .action-text h4 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-home-wrapper .modal-home-company .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-home-wrapper .modal-home-company .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company .field-element a.a-value {
        display: block; }

.inventory-elements .inventory-element {
  border: none !important;
  padding: 0;
  width: 100px;
  height: 130px; }
  .inventory-elements .inventory-element div {
    width: 100px;
    height: 100px;
    border-radius: 3px;
    position: relative;
    border: 1.5px solid #ebe7e7;
    cursor: pointer; }
    .inventory-elements .inventory-element div .btn-selected {
      background-color: #39DE3E;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      left: 100%;
      bottom: 100%;
      transform: translate(-50%, 50%);
      display: flex;
      justify-content: center;
      align-items: center; }
      .inventory-elements .inventory-element div .btn-selected i {
        color: white; }
  .inventory-elements .inventory-element p {
    text-align: center;
    margin: 0;
    margin-top: 4px; }

.photo-elements .photo-element {
  width: 150px;
  height: 150px;
  border-radius: 3px;
  position: relative;
  border: 1.5px solid #ebe7e7; }
  .photo-elements .photo-element .btn-clear-img {
    position: absolute;
    bottom: 100%;
    left: 100%;
    background-color: #f9ac19;
    color: white;
    border: none !important;
    outline: none !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, 50%); }
  .photo-elements .photo-element .progress {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    width: 70%; }
    .photo-elements .photo-element .progress .progress-bar {
      background-color: #f9ac19; }

.photo-elements .custom-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  cursor: pointer; }
  .photo-elements .custom-file .custom-file-input {
    height: 100%;
    border: none !important;
    background-color: transparent !important;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important; }
  .photo-elements .custom-file .custom-file-label {
    height: 100% !important;
    border: none !important;
    background-color: transparent !important;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important; }
    .photo-elements .custom-file .custom-file-label:after {
      content: "";
      background-color: transparent;
      border: none !important;
      cursor: pointer;
      outline: none !important;
      box-shadow: none !important; }

.photo-elements .photo-element-add {
  position: relative;
  height: 150px;
  width: 150px;
  border: 1.5px dashed #f9ac19;
  border-radius: 3px;
  cursor: pointer; }
  .photo-elements .photo-element-add i {
    font-size: 35px;
    color: #707070; }

.modal-inventory-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto; }
  .modal-inventory-wrapper .modal-inventory {
    z-index: 10;
    width: 80%;
    overflow: auto;
    background-color: white;
    padding: 40px; }
    .modal-inventory-wrapper .modal-inventory .btn-selectable {
      padding: 10px !important;
      transition: 0.3s; }
      .modal-inventory-wrapper .modal-inventory .btn-selectable:active {
        background-color: #f9ac19; }
        .modal-inventory-wrapper .modal-inventory .btn-selectable:active i {
          color: white; }
    .modal-inventory-wrapper .modal-inventory .modal-element {
      border-bottom: 1px dashed #707070 !important;
      padding: 5px; }
      .modal-inventory-wrapper .modal-inventory .modal-element .quantity {
        font-size: 20px;
        margin: 10px; }
    .modal-inventory-wrapper .modal-inventory .fragile-icon {
      width: 40px;
      height: 50px;
      position: relative;
      border-radius: 3px;
      border: 1px solid #ebe7e7; }
      .modal-inventory-wrapper .modal-inventory .fragile-icon button {
        position: absolute;
        left: 100%;
        bottom: 100%;
        transform: translate(-50%, 50%);
        width: 20px;
        height: 20px; }
    .modal-inventory-wrapper .modal-inventory .fragile-indicator-box {
      border: 2px dashed #f9ac19;
      padding: 15px;
      border-radius: 3px; }

.loading-feedback {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading-feedback h3 {
    animation-name: text-beating;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    color: white; }

@keyframes text-beating {
  0% {
    color: white; }
  50% {
    color: rgba(255, 255, 255, 0.5); }
  100% {
    color: white; } }

@media screen and (max-width: 800px) {
  .modal-home-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px;
    overflow: auto;
    z-index: 6;
    animation-name: show-modal;
    animation-duration: 0.5s;
    animation-iteration-count: 1; }
  @keyframes show-modal {
    0% {
      top: 100%; }
    100% {
      top: 0; } }
    .modal-home-wrapper .btn-close-modal {
      position: fixed;
      top: 20px;
      right: 20px;
      background-color: #707070;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      z-index: 8;
      animation-name: show-in-late;
      animation-iteration-count: 1;
      animation-duration: 1s; }
  @keyframes show-in-late {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
      .modal-home-wrapper .btn-close-modal i {
        color: white;
        font-size: 30px; }
      .modal-home-wrapper .btn-close-modal:active {
        border: none !important;
        outline: none !important; }
      .modal-home-wrapper .btn-close-modal:hover {
        border: none !important;
        outline: none !important; }
    .modal-home-wrapper .modal-home {
      width: 100%;
      background-color: white;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 0;
      position: relative;
      z-index: 6; }
      .modal-home-wrapper .modal-home .action-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        border-top: 1px solid #ebe7e7;
        cursor: pointer; }
        .modal-home-wrapper .modal-home .action-text h1 {
          color: #f9ac19;
          padding: 0;
          margin: 0; }
      .modal-home-wrapper .modal-home .logo-mudapartner {
        width: 120px; }
      .modal-home-wrapper .modal-home .field-element {
        padding: 10px;
        border-bottom: 1px dashed #707070; }
        .modal-home-wrapper .modal-home .field-element .header {
          font-size: 20px;
          padding: 0;
          margin: 0; }
        .modal-home-wrapper .modal-home .field-element .value {
          padding: 0;
          margin: 0; }
        .modal-home-wrapper .modal-home .field-element a.a-value {
          display: block; } }

.btn {
  outline: none !important;
  box-shadow: none !important; }
  .btn:active {
    outline: none !important;
    box-shadow: none !important; }
  .btn:hover {
    outline: none !important;
    box-shadow: none !important; }

.form-control {
  outline: none !important;
  box-shadow: none !important; }
  .form-control:active {
    border-color: inherit !important; }
  .form-control:focus {
    border-color: inherit !important; }

.custom-select {
  outline: none !important;
  box-shadow: none !important; }
  .custom-select:active {
    border-color: inherit !important; }
  .custom-select:focus {
    border-color: inherit !important; }
